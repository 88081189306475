import gsap from "gsap";
import { startScroll, stopScroll } from "../../utils";
import shuffleLetters from "shuffle-letters";
import youtubeVideo from "../create-video";

const px2vw = (px) => {
  return parseInt(px * window.innerWidth / 1920);
};

const getStartX = (el) => {
  let style = el.currentStyle || window.getComputedStyle(el);
  return parseInt(style.marginLeft) + parseInt(style.width);
}

const getFinX = (el, isMobile) => {
  let style = el.currentStyle || window.getComputedStyle(el);
  let x = window.innerWidth - el.offsetLeft - parseInt(style.width);

  return (!isMobile) ? x - px2vw(180) : x - window.innerWidth / 2;
}

export default function animationsPreloader() {

  const header = document.querySelector("header");
  const aside = document.querySelector("aside");
  const preloader = document.querySelector('.preloader');

  stopScroll();

  if (!preloader) {
    youtubeVideo();
    startScroll();
    return;
  }

  if (preloader && sessionStorage.onceLoaded) {
    gsap.to(preloader, {
      autoAlpha: 0, duration: 0.5, ease: "none", onComplete() {
        preloader.remove();
        youtubeVideo();
        startScroll();
      }
    })

    return;
  }

  const linesWrapper = preloader.querySelector('.lines');
  const lines = linesWrapper.querySelectorAll('div');
  const text = preloader.querySelector('.text span');

  let isLoaded = false;
  let timeScale = 1;
  let timeOut = 3000;
  let quickLoad = false;

  const pos = {
    start: {},
    fin: {}
  };

  if (sessionStorage.onceLoaded) {
    timeScale = 2;
  } else {
    timeScale = 1.5;
  }

  const shuffleText = () => {

    if (quickLoad) return;

    shuffleLetters(text, {
      // iterations: 20,
      onComplete: el => {
        gsap.timeline()
          .to(el, { autoAlpha: 0, duration: 0.15 })
          .call(() => {
            text.innerHTML = text.dataset.newtext;
            shuffleLetters(text);
          })
          .to(el, { autoAlpha: 1, duration: 0.15 })
      }
    });
  }

  const desktopPreloader = () => {

    if (!preloader) return;

    lines.forEach((el, idx) => {
      pos.start[idx] = getStartX(el);
      pos.fin[idx] = getFinX(el);
    })

    const onLoad = gsap.timeline({
      paused: true,
      onComplete() {
        youtubeVideo();
        startScroll();
        sessionStorage.onceLoaded = "true";
        this.kill();
        preloader.remove();
      }
    })
      .to(text, { autoAlpha: 0, duration: 1 }, "onload")
      .to(lines, { scaleY: 25, autoAlpha: 0, duration: 1 }, "onload+=1")
      .to(preloader, { autoAlpha: 0, duration: 1 }, "onload+=1")
      .from([header, aside], { autoAlpha: 0, stagger: 0.5 }, "onload+=1");

    lines.forEach((el, idx) => {
      onLoad
        .to(el, { x: pos.fin[idx], duration: 0.75, ease: "none" }, "onload")
        .to(el, { autoAlpha: 1, duration: 0.75, ease: "none" }, "onload")
    })

    const onLoading = gsap.timeline({
      repeat: -1,
      paused: true,
      onRepeat() {
        if (isLoaded) {
          this.revert();
          preLoading.kill();
          onLoad.timeScale(timeScale).play();
        }
      }
    });

    lines.forEach((el, idx) => {

      let x = (idx !== lines.length - 1) ? pos.start[idx + 1] : 0;
      let alpha = (idx === lines.length - 1) ? 0 : 1;

      onLoading
        .to(el, { x: x, duration: 0.75, ease: "none" }, "tl")
        .to(el, { autoAlpha: alpha, duration: 0.75, ease: "none" }, "tl");
    })


    const preLoading = gsap.timeline({
      onStart: () => {
        onLoading.timeScale(timeScale).play();
        setTimeout(() => {
          isLoaded = true;
        }, timeOut);
      }
    })
      .call(shuffleText, null, "preload")
      .to([linesWrapper, text], { autoAlpha: 1, duration: 1, ease: "none" }, "preload+=0.5")
  }

  const mobilePreloader = () => {

    if (!preloader) {
      startScroll();
      return;
    };

    lines.forEach((el, idx) => {
      pos.start[idx] = getStartX(el);
      pos.fin[idx] = getFinX(el, true);
    })

    const onLoad = gsap.timeline({
      paused: true,
      onComplete() {
        sessionStorage.onceLoaded = "true";
        this.kill();
        preloader.remove();
        youtubeVideo();
        startScroll();
      }
    })
      .to(text, { autoAlpha: 0, duration: 1 }, "onload")
      .to(lines, { scaleY: 0, autoAlpha: 0, duration: 0.5 }, "onload+=1")
      .to(preloader, { autoAlpha: 0, duration: 1 }, "onload+=1")
      .from([header, aside], { autoAlpha: 0, stagger: 0.25 }, "onload+=1");

    lines.forEach((el, idx) => {
      onLoad
        .to(el, { x: pos.fin[idx], duration: 1 }, "onload")
        .to(el, { autoAlpha: 1, duration: 1, ease: "none" }, "onload")
    })

    const onLoading = gsap.timeline({
      repeat: -1,
      paused: true,
      onRepeat() {
        if (isLoaded) {
          this.revert();
          preLoading.kill();
          onLoad.timeScale(timeScale).play();
        }
      }
    });

    lines.forEach((el, idx) => {

      let x = (idx !== lines.length - 1) ? pos.start[idx + 1] : 0;
      let alpha = (idx === lines.length - 1) ? 0 : 1;

      onLoading
        .to(el, { x: x, duration: 0.75, ease: "power1.in" }, "tl")
        .to(el, { autoAlpha: alpha, duration: 0.75, ease: "none" }, "tl");
    })


    const preLoading = gsap.timeline({
      onStart: () => {
        onLoading.timeScale(timeScale).play();
        setTimeout(() => {
          isLoaded = true;
        }, timeOut);
      }
    })
      .call(shuffleText, null, "preload")
      .to([linesWrapper, text], { autoAlpha: 1, duration: 1, ease: "none" }, "preload+=0.5")
  }





  gsap.matchMedia()
    .add("(min-width: 1025px)", () => {
      desktopPreloader();
    })
    .add("(max-width: 1024px)", () => {
      mobilePreloader();
    });



};