import { gsap} from "gsap/all";
import { _debounce } from "../../utils";

const customPointer = () => {
  const single = document.body.classList.contains("single");
  let wrapper = document.querySelector(".hex-pattern");

  if (single) {
    wrapper.remove();
    return;
  }

  const gmm = gsap.matchMedia();
  let isLoaded = false;
  let svg;

  let ns = "http://www.w3.org/2000/svg";
  let hexs;
  let hexRadius;

  let points = [];
  let pointer;
  let pointerInner;
  let pointerOuter1;
  let pointerOuter2;

  let quickMoveInner;
  let quickMoveOuter1;
  let quickMoveOuter2;

  let mouse = { x: 0, y: 0, prevX: 0, prevY: 0 };
  let showPointer = false;

  function addHexCenterPoints() {
    hexs = [...svg.querySelectorAll(".hex")];

    hexs.forEach((h, idx) => {
      let bbox = h.getBBox();
      let center = {
        x: ~~(bbox.x + bbox.width / 2),
        y: ~~(bbox.y + bbox.height / 2),
      };

      if (idx === 0) {
        hexRadius = bbox.width / 2;
      }

      let point = svg.createSVGPoint();
      point.x = center.x;
      point.y = center.y;
      points[idx] = point;
    });
  }

  async function loadPointer() {
    let response = await fetch(window.BAMBOO.assets + "img/hex-pattern.svg");

    if (response.ok) {
      let svgNS = await response.text();
      wrapper.innerHTML = svgNS;
      svg = wrapper.querySelector("svg");
      isLoaded = true;
      createPointer();
    } else {
      console.log(response);
    }
  }

  function createPointer() {
    !points.length && addHexCenterPoints();

    if (!pointer) {
      pointer = document.createElementNS(ns, "g");
      pointerInner = document.createElementNS(ns, "circle");
      pointerOuter1 = document.createElementNS(ns, "circle");
      pointerOuter2 = document.createElementNS(ns, "circle");

      pointerInner.setAttribute("r", hexRadius * 1.2);
      pointerOuter1.setAttribute("r", hexRadius * 3.5);
      pointerOuter2.setAttribute("r", hexRadius * 7.8);

      pointer.appendChild(pointerInner);
      pointer.appendChild(pointerOuter1);
      pointer.appendChild(pointerOuter2);

      pointer.classList.add("custom-pointer");
      svg.appendChild(pointer);

      quickMoveInner = gsap.quickSetter(pointerInner, "attr");
      quickMoveOuter1 = gsap.quickSetter(pointerOuter1, "attr");
      quickMoveOuter2 = gsap.quickSetter(pointerOuter2, "attr");
    }

    movePointer();
  }

  function movePointer() {
    const hexToggle = gsap
      .timeline({ paused: true })
      .from(svg, { duration: 0.3, autoAlpha: 0 });

    const setPointerCoords = () => {
      quickMoveInner({ cx: mouse.x, cy: mouse.y });
      quickMoveOuter1({ cx: mouse.x, cy: mouse.y });
      quickMoveOuter2({ cx: mouse.x, cy: mouse.y });

      hexs.forEach((hex, idx) => {
        let hovered = pointerInner.isPointInFill(points[idx]);
        let outer1 =
          pointerOuter1.isPointInFill(points[idx]) &&
          !hex.classList.contains("hovered");
        let outer2 =
          pointerOuter2.isPointInFill(points[idx]) &&
          !hex.classList.contains("hovered") &&
          !hex.classList.contains("around1");

        hex.classList.toggle("hovered", hovered);
        hex.classList.toggle("around1", outer1);
        hex.classList.toggle("around2", outer2);
      });
    };

    const getPointerCoords = (e) => {
      let CTM = svg.getScreenCTM();

      mouse.x = (e.clientX - CTM.e) / CTM.a;
      mouse.y = (e.clientY - CTM.f) / CTM.d;
    };

    const start = () => {
      if (!showPointer) {
        showPointer = true;
        window.addEventListener("mousemove", getPointerCoords, false);
        gsap.ticker.add(setPointerCoords);
        hexToggle.play();
      }
    };

    const stop = () => {
      if (showPointer) {
        showPointer = false;
        window.removeEventListener("mousemove", getPointerCoords, false);
        gsap.ticker.remove(setPointerCoords);
        hexToggle.reverse();
      }
    };

    document.body.addEventListener("mouseleave", stop);
    document.body.addEventListener("mouseenter", start);

    document.body.addEventListener("wheel", (e) => {
      stop();
      document.body.onmousemove = () => {
        start();
      };
    });

    start();
  }

  gmm.add("(min-width: 1024px)", function () {
    if (!isLoaded) {
      loadPointer();
    } else {
      createPointer();
    }
  });
};

export { customPointer };
