export default function Notified() {
  const container = document.querySelector("#notifiedWrapper");
  if (!container) return;

  // Create a new Intersection Observer instance
  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      // If the element is in the viewport
      if (entry.isIntersecting) {
        // Load the script
        let script = document.createElement('script');
        script.async = true;
        script.src = 'https://amplify.notified.com/LiveFeed/1951/loaderscript.js';
        document.body.appendChild(script);

        // Stop observing the element
        observer.unobserve(entry.target);
      }
    });
  }, { rootMargin: '0px', threshold: 1.0 }); // Config options

  observer.observe(container);
}