import gsap from "gsap";
import { _debounce } from "../utils";
import renderCommunities from "./render-communities";
import renderEvents from "./render-events";
import renderModels from "./render-models";

const ajaxLoader = () => {
  const section = document.querySelector("[data-ajax-content]");
  if (!section) return;

  const url = new URL(document.URL);
  let hash = url.hash;
  hash && (hash = hash.substring(1));

  const loadMoreButton = section.querySelector(".load_more");
  const tagsList = section.querySelector(".tags-content");
  const tags = [...tagsList.querySelectorAll(".tag")];
  const footerTags = document.querySelectorAll("footer .nav-community a");
  const searchForm = section.querySelector("form");
  const searchInput = searchForm.querySelector("input");
  const reset = searchInput.closest(".search").querySelector(".reset");
  const mobSearch = document.querySelectorAll(".mob_search");
  const container = section.querySelector("[data-posts-container]");

  const title = section.querySelector(".title-wrapper");
  const tagTitle = title?.querySelector(".active_tag") ?? null;

  if (tagTitle) {
    tagTitle.textContent = hash ? hash : tags[0].textContent;
  }

  let eventsContainer;
  let archivesContainer;

  let postType = searchInput.dataset.postType;
  let cat_id = "";
  let isFetching = false;
  let paged = 1;

  // Start searching on input
  searchInput.value = "";

  searchInput.addEventListener(
    "keyup",
    _debounce(() => {
      let value = searchInput.value.trim();
      // console.log(value);
      reset.classList.toggle("active", value !== "");
      paged = 1;
      cat_id = "";
      value.length && !isFetching && ajaxFetch();
    }, 200)
  );

  mobSearch.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      searchCont.classList.toggle("active");
    });
  });

  // reset search
  reset.addEventListener("click", (e) => {
    searchInput.value = "";
    reset.classList.remove("active");
    ajaxFetch();
  });

  // Start searching on tag click
  tags.length &&
    tags.forEach((tag, i) =>
      tag.addEventListener("click", (e) => {
        e.preventDefault();

        // let hashTitle = tag.textContent.trim();
        // let url_ob = new URL(document.URL);
        // console.log(url_ob);
        // url_ob.hash = `#${hashTitle}`;
        // console.log(url_ob.href);

        // document.location.href = url_ob.href;

        let { slug } = tag.dataset;

        if (slug) {
          window.location.hash = slug;
        } else {
          let u = window.location.href.replace(/#.*$/, "");
          window.history.replaceState("", document.title, u);
        }

        cat_id = tag.dataset.id;
        tags.forEach((tag, idx) => tag.classList.toggle("active", idx == i));
        paged = 1;

        tagTitle &&
          gsap
            .timeline()
            .to(tagTitle, { duration: 0.5, autoAlpha: 0, yPercent: 100 })
            .call(() => {
              tagTitle.innerHTML = tag.innerHTML;
            }, null)
            .to(tagTitle, { duration: 0.5, autoAlpha: 1, yPercent: 0 });

        ajaxFetch();
      })
    );

  const insertToContainer = (posts, wrapper = container) => {
    wrapper.innerHTML = posts;
  };

  const appendToContainer = (posts, wrapper = container) => {
    wrapper.innerHTML += posts;
  };

  const getRenderedComponents = (postType, data) => {
    switch (postType) {
      case "community":
        return renderCommunities(data);
      case "models":
        return renderModels(data);
      case "events":
        eventsContainer = section.querySelector("[data-events-container]");
        archivesContainer = section.querySelector("[data-archives-container]");
        return renderEvents(data);
      default:
        return null;
    }
  };

  const render = (data) => {
    if (data.error) {
      container.classList.add("error");
      section.classList.contains("events-page")
        ? (container.querySelector(".error").innerHTML = data.error)
        : (container.innerHTML = data.error);
      return;
    } else {
      container.classList.remove("error");
    }

    let rc = getRenderedComponents(postType, data);

    if (rc) {
      if (postType === "events") {
        paged === 1
          ? insertToContainer(rc.events, eventsContainer)
          : appendToContainer(rc.events, eventsContainer);

        paged === 1
          ? insertToContainer(rc.archives, archivesContainer)
          : appendToContainer(rc.archives, archivesContainer);

        container.classList.toggle("events-none", !rc.events.length);
        container.classList.toggle("archives-none", !rc.archives.length);
      } else {
        paged === 1 ? insertToContainer(rc) : appendToContainer(rc);
      }
    }

    loadMoreButton && loadMoreButton.classList.toggle("hidden", data.pages <= paged);
  };

  async function ajaxFetch() {
    isFetching = true;

    let data = new FormData();

    data.append("action", "a-search");
    data.append("post_type", postType);
    data.append("cat_id", cat_id);
    data.append("s", searchInput.value);
    data.append("paged", paged);

    // Send request
    let response = await fetch(BAMBOO.ajaxUrl, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });

    if (response.ok) {
      let data = await response.json();
      // console.log(data);
      render(data);
      isFetching = false;
    } else {
      // console.log(response.status);
      isFetching = false;
    }
  }

  if (hash) {
    let t = tags.find((t, i) => t.dataset.slug == hash.toLowerCase());
    t && t.click();
  }

  if (section.classList.contains("community-page")) {
    footerTags.forEach((tag) => {
      tag.addEventListener("click", (e) => {
        let { slug } = tag.dataset;
        let t = tags.find((t, i) => t.dataset.slug == slug.toLowerCase());
        t && t.click();
        t && window.scrollTo(0, 0);
      });
    });
  }

  // Load more
  loadMoreButton &&
    loadMoreButton.addEventListener("click", () => {
      if (!isFetching) {
        paged++;
        ajaxFetch();
      }
    });
};

export { ajaxLoader };
