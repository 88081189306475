import Swiper, { FreeMode } from "swiper";
import gsap from "gsap";

export default function sliderSoft() {
  const soft = document.querySelectorAll(".custom-grid-slider");
  if (!soft.length) return;
  let w = window.innerWidth;

  soft.forEach((s) => {
    initSlider(s);

    if (w <= 1024) return;
    const softs = s.querySelectorAll(".soft .slide");

    // softs.length > 4 &&

    softs.forEach((soft) => {
      // let desc = soft.querySelector(".description");
      // let tl = gsap.timeline({ paused: true });
      // tl.to(desc, {
      //   duration: 0.3,
      //   autoAlpha: 0,
      //   height: 0,
      //   ease: "none",
      // });

      // const pl = () => {
      //   if (w <= 1024) return;
      //   tl.play();
      // };
      // const rv = () => {
      //   if (w <= 1024) return;
      //   tl.reverse();
      // };

      // pl();
      // soft.addEventListener("mouseenter", rv);
      // soft.addEventListener("mouseleave", pl);
    });

    window.addEventListener("resize", (e) => (w = window.innerWidth));
  });

  function initSlider(s) {
    const swiper = new Swiper(s, {
      modules: [FreeMode],
      freeMode: true,
      slidesPerView: "auto",
      grabCursor: true,
    });
  }
}
