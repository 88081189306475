import { startScroll, stopScroll } from "../utils";

export default function toggleMenu() {
  const header = document.querySelector("header");
  const mobileBurger = header.querySelector(".mobile-burger");

  const desktopBurger = header.querySelector(".desktop-burger");
  const headerCloseButton = header.querySelector(".desktop-close");

  const openMenu = () => {
    stopScroll();
    header.classList.add("active");
  };

  const closeMenu = () => {
    startScroll();
    header.classList.remove("active");
  };

  const onScroll = (e) => {

    if (e) {
      if (e.pageY > 100) {
      // if (e.isScrolling && e.scrollTop > 100) {
        header.classList.add("scrolling");
        header.classList.remove("opened");
      }

      if (e.pageY < window.innerHeight - 100) {
        header.classList.remove("opened");
        header.classList.remove("scrolling");
      }
    } else {
      if (window.scrollY > 0) {
        header.classList.add("scrolling");
        header.classList.remove("opened");
      }
    }
  };

  mobileBurger.addEventListener("click", () => {
    !header.classList.contains("active") ? openMenu() : closeMenu();
  });

  desktopBurger.addEventListener("click", () => {
    header.classList.add("opened");
    header.classList.remove("scrolling");
    stopScroll();
  });

  headerCloseButton.addEventListener("click", () => {
    header.classList.remove("opened");
    header.classList.add("scrolling");
    startScroll();
  });

  window.addEventListener("wheel", onScroll);
  onScroll();
}
