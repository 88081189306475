import autosize from "autosize";

export default function Form() {
  const content = document.querySelectorAll(".form_content");

  if (!content.length) return;

  const popups = document.querySelector(".pop-up");

  const closePopUp = document.querySelectorAll(".close_btn");
  const textarea = [...document.querySelectorAll("textarea")];
  autosize(textarea);

  // Form functions
  function useForm(c) {
    const form = c.querySelector("form");
    const method = form.method;
    const inputs = [...form.elements].filter(
      (i) =>
        i.type !== "submit" &&
        i.type !== "hidden" &&
        i.type !== "fieldset" &&
        i.type !== "button"
    );
    const submit = c.querySelector("button.submit");
    const labels = inputs.map((i) => i.closest("label")).filter((i) => i);
    const messageLength = c.querySelector(".count .l");
    const acceptance = c.querySelector("input[type=checkbox]");

    // On validate
    function onValidate(data) {
      c.classList.remove("loading");
      switch (data.status) {
        case "validation_failed":
          onValidateError(data.invalid_fields);
          break;
        case "mail_sent":
          onSuccess(data.message);
          break;
        case "mail_error":
        case "mail_failed":
          onServerError(data);
          break;
        default:
          console.log("default");
      }
    }

    // Before request
    const beforeSend = () => {
      // Remove class 'error' from label
      labels.forEach((label) => {
        // let t = label.querySelector(".tip");
        // t && (t.innerHTML = "");
        label.classList.remove("error");
      });

      // Lock submit button to prevent multiple clicks
      submit.disabled = true;

      c.classList.add("loading");
    };

    // After successful request
    const onSuccess = (message) => {
      //Show message
      popups.querySelector(".message").innerHTML = message;

      //Open pop up & Disable Body scroll
      showPopUp();

      // Reset form
      inputs.forEach((input) => {
        input.closest("label")?.classList?.remove("active");

        if (input.type == "checkbox") {
          input.checked = false;
          return;
        }

        input.value = "";
        messageLength.innerHTML = "0";
        input.setAttribute("value", "");
      });

      // Update autosize
      setTimeout((e) => autosize.update(textarea));
    };

    // Form validation error
    const onValidateError = (errors) => {
      // submit.disabled = false;

      errors.forEach((error) => {
        let input = inputs.filter((i) => i.name === error.field);

        let label = input[0].closest("label");
        let t = label.querySelector(".tip");
        label && label.classList.add("error");
        t && (t.innerHTML = error.message);
      });
    };

    // Server error
    const onServerError = (data) => {
      onSuccess(data.message);
      popups.classList.add("error");
    };

    // Send form via AJAX
    const sendForm = () => {
      if (acceptance && !acceptance.checked) {
        acceptance.classList.add("error");
        setTimeout(() => acceptance.classList.remove("error"), 300);
        return;
      }

      // Before request send
      beforeSend();

      // Form data
      let data = new FormData(form);
      let url = `${BAMBOO.baseUrl}/wp-json/contact-form-7/v1/contact-forms/${form.id}/feedback`;
      data.append("action", "submit_ccform");

      // Send request
      fetch(url, {
        method: method,
        credentials: "same-origin",
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          submit.disabled = false;
          onValidate(data);
        })
        // Fetch error
        .catch((err) => {
          onServerError(err);
        });
    };

    inputs.forEach((input) => {
      input.addEventListener("input", () => {
        // console.log("input");
        let label = input.closest("label");
        let err = label.classList.contains("error");
        let v = input.value;
        if (err) label.classList.remove("error");
        label.classList.toggle("active", v);

        if (input.type == "textarea") {
          messageLength.innerHTML = v ? input.textLength : "0";
        }
      });

      input.addEventListener("change", () => {
        let v = input.value;
        input.value = " ";
        setTimeout(() => (input.value = v), 0);
      });
    });

    // Submit form on click
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      // console.log("submit");
      sendForm();
    });
  }

  function showPopUp() {
    popups.classList.add("active");
    document.addEventListener("keydown", hideOnEscape);
    document.addEventListener("click", hideOnOutside);
  }

  function hidePopUp() {
    popups.classList.remove("active");
    document.removeEventListener("keydown", hideOnEscape);
    document.removeEventListener("click", hideOnOutside);

    setTimeout(() => {
      popups.classList.remove("error");
    }, 400);
  }

  // Close PopUp on Escape keydown
  function hideOnEscape(e) {
    if (e.key === "Escape") hidePopUp();
  }

  // Close PopUp on click outside Popup
  function hideOnOutside(e) {
    if (e.target.id == "pop-up") hidePopUp();
  }

  [...content].forEach((c) => useForm(c));
  [...closePopUp].forEach((b) => b.addEventListener("click", hidePopUp));
}
