import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import { stopScroll, startScroll } from "../utils";
import PlayerFactory from "youtube-player";

const youtubeVideo = () => {
  const videos = document.querySelectorAll(".initVideoPlayer");
  if (!videos.length) return;

  const pop_up = document.querySelector(".video_pop-up");
  const video = pop_up.querySelector(".video");
  const close = pop_up.querySelectorAll(".close");

  let yt;

  const openPopUp = () => {
    stopScroll();
    pop_up.classList.add("active");
  };
  const closePopUp = () => {
    yt && yt.stopVideo();
    pop_up.classList.remove("active");
    startScroll();
  };

  const initVideo = (el, id) => {
    yt = PlayerFactory(el);
    yt.loadVideoById(id);
  };

  const createVideo = (id) => {
    let div = document.createElement("div");
    video.innerHTML = "";
    video.paused = true;
    video.append(div);
    pop_up.dataset.hash = id;
    initVideo(div, id);
    yt.stopVideo();
  };

  const initOnClick = (el, id) => {
    el.addEventListener("click", (e) => {
      pop_up.dataset.hash == id ? yt.playVideo() : createVideo(id);
      openPopUp();
      yt.playVideo();
    });
  };

  const initOnScroll = (v, id) => {
    let section = v.closest("section");

    ScrollTrigger.create({
      trigger: section,
      once: true,
      onEnter() {
        initVideo(v, id);
        yt.stopVideo();
      },
    });
  };

  // Close video pop-up on click
  close.forEach((c) => c.addEventListener("click", closePopUp));

  // Create players list
  videos.forEach((v) => {
    const delay = v.dataset.delay;
    const id = v.dataset.hash;
    delay ? initOnClick(v, id) : initOnScroll(v, id);
  });
};

export default youtubeVideo;
