import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
import { _debounce } from "../../utils";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import { revealAnimations } from "./animations-reveal";

const onscrollAnimations = () => {
  const html = document.querySelector("html");
  const sections = [...document.querySelectorAll("main>section")];
  const welcome = document.querySelector(".home .welcome");
  const invertedSections = sections.filter((s) => s.dataset.inverted);
  const header = document.querySelector("header");
  const headerOffset = header.clientHeight / 2;
  const aside = document.querySelector("aside");
  const asideNum = aside.querySelector(".nums .active");
  const asideNumOffset = asideNum.getBoundingClientRect();
  const asideArrows = [...aside.querySelectorAll(".arrows button")];
  const asideArrowsOffsets = asideArrows.map((arrow) =>
    arrow.getBoundingClientRect()
  );

  let activeIdx = 0;
  let currentIdx = 0;
  let visibleIdx;
  let scrollDirection;
  let scrollBack = false;

  // Invert on scroll
  const toggleInvert = (section) => {
    // Invert header
    ScrollTrigger.create({
      trigger: section,
      start: `top ${headerOffset - 1}px`,
      end: `bottom ${headerOffset - 1}px`,
      // markers: true,
      onToggle: (e) => {
        setTimeout(
          () => header.classList.toggle("inverted", e.isActive),
          e.isActive ? 1 : 0
        );
      },
    });

    // Invert aside arrows
    asideArrows.forEach((a, idx) => {
      const offset = asideArrowsOffsets[idx];

      ScrollTrigger.create({
        trigger: section,
        start: `top ${offset.bottom}px`,
        end: () => `+=${section.clientHeight}px`,
        onToggle: (e) => a.classList.toggle("inverted", e.isActive),
      });
    });
  };

  // Sidebar navigation
  const sectionsNavAside = () => {
    // Hide navigation if only 1 section
    if (sections.length < 2) {
      aside.classList.add("hidden");
      return;
    }

    const arrows = aside.querySelector(".arrows");
    const arrowTop = arrows.querySelector(".top");
    const arrowDown = arrows.querySelector(".bottom");

    const nums = aside.querySelector(".nums");
    const activeNum = nums.querySelector(".active");
    const allNums = nums.querySelector(".all");

    allNums.textContent = sections.length;

    const toggleActiveIdx = () => {
      if (currentIdx === activeIdx) return;

      visibleIdx = activeIdx < 9 ? `0${activeIdx + 1}` : activeIdx + 1;

      gsap
        .timeline({
          onComplete() {
            currentIdx = activeIdx;
          },
        })
        .to(activeNum, {
          duration: 0.2,
          autoAlpha: 0,
          xPercent: 100 * scrollDirection,
        })
        .set(activeNum, { xPercent: -100 * scrollDirection })
        .call(() => (activeNum.textContent = visibleIdx))
        .to(activeNum, { duration: 0.2, xPercent: 0, autoAlpha: 1 });

      arrowTop.classList.toggle("disabled", activeIdx === 0);
      arrowDown.classList.toggle("disabled", activeIdx === sections.length - 1);
    };

    const goTop = (e) => {
      activeIdx = scrollBack ? 0 : --activeIdx;
      let scrollTo = activeIdx === 0 ? 0 : sections[activeIdx];
      html.classList.add("is-scrolling");
      gsap.to(window, {
        duration: 0.75,
        scrollTo: scrollTo,
        onComplete: () => {
          html.classList.remove("is-scrolling");
        },
      });
    };

    const goDown = (e) => {
      activeIdx++;
      html.classList.add("is-scrolling");
      gsap.to(window, {
        duration: 0.75,
        scrollTo: sections[activeIdx],
        onComplete: () => {
          html.classList.remove("is-scrolling");
        },
      });
    };

    sections.forEach((s, idx) => {
      let start = asideNumOffset.top;
      let end = asideNumOffset.bottom;

      let trigger = s.closest(".pin-spacer") ?? s;
      s.classList.contains("welcome") && (trigger = s);
      // let bottom = s.dataset.overScroll
      //   ? `${parseInt(s.dataset.overScroll)}px`
      //   : "bottom";

      ScrollTrigger.create({
        trigger: trigger,
        // markers: true,
        start: `top ${start}px`,
        end: `bottom ${end}px`,
        onUpdate(e) {
          scrollDirection = e.direction;
        },
        onToggle(e) {
          if (e.isActive) {
            asideNum.classList.toggle(
              "inverted",
              s.dataset.inverted === "true"
            );
            activeIdx = idx;
            toggleActiveIdx();
          }
        },
      });
    });

    arrowTop.addEventListener("click", goTop);
    arrowDown.addEventListener("click", goDown);

    // Hide aside navigation on footer
    ScrollTrigger.create({
      trigger: sections[sections.length],
      start: `bottom center`,
      onToggle(e) {
        scrollBack = e.isActive;
        aside.classList.toggle("invisible", e.isActive);
      },
    });
  };

  const animateWelcome = () => {
    const title = welcome.querySelector(".title");
    const button = welcome.querySelector("button");

    ScrollTrigger.create({
      trigger: welcome,
      pin: true,
      // markers: true,
      pinSpacing: false,
      start: "bottom bottom",
    });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: sections[1],
          scrub: true,
          // markers: true,
        },
      })
      .to(welcome, { yPercent: -75 }, "welcome")
      .to([title, button], { y: "10vh", autoAlpha: 0 }, "welcome");

    setTimeout(() => welcome.parentNode.classList.add("inited"), 22);
  };

  // const pinSections = () => {
  //   return;
  //   const pinned = [".home .features", ".home .events"];
  //   // const pinned = ['.page_started', '.home .features', '.home .events'];

  //   pinned.forEach((s) => {
  //     let section = document.querySelector(s);
  //     if (!section) return;

  //     let scroller = section.querySelector("[data-scroller]");
  //     let scrollHeight = scroller.scrollHeight;
  //     section.dataset.overScroll = scrollHeight + section.clientHeight;

  //     ScrollTrigger.create({
  //       trigger: s,
  //       pin: true,
  //       // markers: true,
  //       end: `+=${scrollHeight}px`,
  //       onUpdate(e) {
  //         let offset = scrollHeight * e.progress.toFixed(2);
  //         gsap.to(scroller, { scrollTo: offset });
  //       },
  //     });
  //   });
  // };

  revealAnimations();

  gsap.matchMedia().add("(min-width: 1024px)", () => {
    welcome && animateWelcome();
    // pinSections();
    sectionsNavAside();
  });

  invertedSections.length && invertedSections.forEach((s) => toggleInvert(s));
};

export { onscrollAnimations };
