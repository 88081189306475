import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import Splitting from "splitting";

const revealAnimations = () => {

  const sections = [...document.querySelectorAll('section')];
  const lastSection = (sections.length) ? sections[sections.length] : sections[0];
  const welcome = document.querySelector('.home .welcome');
  const main = document.querySelector('main');
  const footer = document.querySelector('footer');

  const fades = [...document.querySelectorAll('[data-fade]')];
  const splitting = [...document.querySelectorAll('[data-splitting]')];
  const staggers = [...document.querySelectorAll('[data-stagger]')];
  const scrollParallaxItems = [...document.querySelectorAll('[data-parallax-scroll]')];
  // const revealedFromBottom = [...document.querySelectorAll('footer')];

  const animateFades = (el) => {

    let type = el.dataset.fade;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        toggleActions: "play none none reverse",
        start: "top 80%",
        // markers: true
      }
    });

    switch (type) {
      case 'in':
        tl.from(el, { duration: 2, autoAlpha: 0, ease: "none" });
        break;
      case 'up':
        tl.from(el, { duration: 1, y: 100, autoAlpha: 0, ease: "none" });
        break;
      default:
        break;
    }
  }

  const textReveal = (revealText) => {

    let results = Splitting({ target: revealText, by: "lines" });

    results.forEach((splitResult) => {
      const wrappedLines = splitResult.lines
        .map(
          (wordsArr) => `
        <span class="line"><div class="words">
          ${wordsArr
              .map(
                (word) => `${word.outerHTML}<span class="whitespace">
         </span>`
              )
              .join("")}
        </div></span>`
        )
        .join("");
      splitResult.el.innerHTML = wrappedLines;
    });

    let revealLines = revealText.forEach((element) => {
      const lines = element.querySelectorAll(".line .words");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          toggleActions: "restart none none reset",
        }
      });
      tl.set(revealText, { autoAlpha: 1 });
      tl.from(lines, {
        duration: 1,
        yPercent: 100,
        ease: 'power3.out',
        stagger: 0.25,
        delay: 0.2
      });
    });
  }

  const animateStaggers = (el) => {

    let items = [...el.querySelectorAll('[data-item]')];
    if (!items.length) return;

    let type = el.dataset.stagger;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        toggleActions: "play none none reverse",
      }
    });

    switch (type) {
      case 'fadein':
        tl.from([items], { duration: 1, autoAlpha: 0, ease: "none", stagger: 0.2 });
        break;
      case 'fadeup':
        tl.from([items], { duration: 1, autoAlpha: 0, yPercent: 50, stagger: 0.3 });
        break;
      default:
        break;
    }
  }

  const parallaxAnimation = (el) => {

    let options = JSON.parse(el.dataset.parallaxScroll);

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        scrub: 1,
        // markers: true
      }
    });

    tl.to(el, { yPercent: options?.yPercent ?? 0, y: options?.y ?? 0 })
  }

  const revealAnimation = (el) => {

    gsap.timeline({
      scrollTrigger: {
        trigger: sections[sections.length - 1],
        scrub: true,
        // markers: true,
        start: "bottom bottom",
        end: `+=${el.clientHeight}px`,
      }
    })
      .from(el, { yPercent: 50 })
  }

  // Footer animation
  const revealFooter = () => {

    gsap.matchMedia().add("(min-width: 1024px)", () => {

      const footerContent = footer.querySelector('.footer-content');

      const updateBottomMargin = () => {
        main.style.marginBottom = `${footer.scrollHeight}px`;
        ScrollTrigger.refresh();
      }

      const resizeObserver = new ResizeObserver(entries => updateBottomMargin());
      resizeObserver.observe(document.body);

      gsap.timeline({
        scrollTrigger: {
          trigger: lastSection,
          scrub: true,
          // markers: true,
          start: "bottom bottom",
          end: () => `+=${footer.scrollHeight}`,
        }
      })
        .to(footer, { y: 0, ease: "none" }, "footer")
        // .to(footerContent, { autoAlpha: 1, ease: "none" }, "footer");

      return () => {
        resizeObserver.unobserve(footer);
        main.style.marginBottom = '0px';
      }
    })
  }


  fades.length && fades.forEach(el => animateFades(el));
  splitting.length && textReveal(splitting);
  staggers.length && staggers.forEach(el => animateStaggers(el));
  scrollParallaxItems.length && scrollParallaxItems.forEach(el => parallaxAnimation(el));
  // revealedFromBottom.length && revealedFromBottom.forEach(el => revealAnimation(el));
  footer && revealFooter();
}

export { revealAnimations }