import { startScroll, stopScroll } from "../utils";

import Zoomist from "../../../node_modules/zoomist/dist/zoomist";

export default function ZoomImage() {
  const images = document.querySelectorAll(".single .grid-content img");

  if (!images.length) return;
  const popUp = document.querySelector(".zoom_pop-up");
  const closeButton = popUp.querySelectorAll(".close");
  const zoomButton = popUp.querySelectorAll(".zoom_change");
  const openButton = [];
  const imageSRC = popUp.querySelector(".image");
  let WZ, ZOOM;

  const stop = () => {
    setTimeout(stopScroll, 100);
  };
  const start = () => {
    setTimeout(startScroll, 100);
  };

  const openPopUp = (img) => {
    popUp.classList.add("active");
    imageSRC.dataset.src = img.src;
    let ch = img.clientHeight;
    let cw = img.clientWidth;
    let ih = window.innerHeight * 0.8;
    let iw = window.innerWidth;
    let coff = ((iw * 0.85) / cw) * ch;

    stop();

    WZ = new Zoomist(imageSRC, {
      src: "data-src",
      height: coff > ih ? `${ih / iw / 0.0085}%` : "auto",
      fill: coff > ih ? "contain" : "cover",
      maxRatio: iw > 768 ? 3 : 5,
      zoomer: {
        outEl: zoomButton[0],
        inEl: zoomButton[1],
        disableOnBounds: true,
      },
      on: {
        dragStart() {
          this.getZoomRatio() !== 1 && (imageSRC.style.cursor = "grabbing");
        },
        dragEnd() {
          this.getZoomRatio() !== 1 && (imageSRC.style.cursor = "grab");
        },
        zoom(ratio) {
          !isNaN(ratio) && (ZOOM = ratio);
          ratio < 0.5 && this.zoomTo(0.5);
          isNaN(ratio) && this.update();
        },
      },
    });

    // WZ.on("update", (e) => {});
    document.addEventListener("keydown", hideOnEscape);
    document.addEventListener("mouseenter", stop);
  };

  const closePopUp = () => {
    popUp.classList.remove("active");
    start();

    document.removeEventListener("mouseenter", stop);

    setTimeout(() => {
      WZ.destroy();
      WZ = null;
      imageSRC.removeAttribute("style");
    }, 300);
    document.removeEventListener("keydown", hideOnEscape);
  };

  // Close PopUp on Escape keydown
  const hideOnEscape = (e) => {
    if (e.key === "Escape") closePopUp();
  };

  const createWrapper = (img) => {
    let div = document.createElement("div");
    let btn = document.createElement("button");
    div.classList.add("zoomed");
    btn.classList.add("zoom_image");

    img.parentNode.insertBefore(div, img);
    div.appendChild(img);
    div.appendChild(btn);
    openButton.push(btn);
    div.style.width = img.offsetWidth + "px";
    img.addEventListener("click", (e) => openPopUp(img));
  };

  images.forEach((img) => createWrapper(img));
  closeButton.forEach((b) => b.addEventListener("click", closePopUp));
}
