import Swiper from "swiper";

export default function sliderInstagram() {
  const insta = document.querySelector(".insta_slider");
  if (!insta) return;

  const icons = insta.querySelectorAll(".swiper-slide");
  icons.length > 3 && initSlider();

  function initSlider() {
    const swiper = new Swiper(insta, {
      // freeMode: true,
      slidesPerView: "auto",
      grabCursor: true,
      loop: true,
    });
  }
}
