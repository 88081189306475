const privacyTags = () => {
  const privacy = document.querySelector(".privacy-page");
  if (!privacy) return;

  const choose = privacy.querySelector(".mobile_choose");
  if (!choose) return;

  const activeTag = choose.querySelector(".active_title");
  const tagCont = privacy.querySelector(".tags_list");
  const tags = tagCont.querySelectorAll(".tag");

  choose.addEventListener("click", () => {
    tagCont.classList.toggle("active");
  });

  tags.forEach((tag) => {
    tag.addEventListener("click", () => {
      tagCont.classList.remove("active");
      choose.classList.add("active");
      activeTag.innerHTML = tag.textContent;
    });
  });
};

export default privacyTags;
