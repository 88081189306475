export default function renderEvents(data) {

  let events = data.posts.events;
  let archives = data.posts.archived;
  let renderedEvents = '';
  let renderedArchives = '';

  const renderPost = (data) => {

    let dateArr = data.date_s ? data.date_s.split("-") : ['', ''];

    let post = `<a href="${data.link}" class="event_icon post" data-item>
    <div class="data">
      <span class="n">${dateArr[0]}</span>
      <span class="m">${dateArr[1]}</span>
    </div>
    <div>
      <h4 class="title">${data.title}</h4>
      <p class="info">${data.excerpt ?? data.content}</p>
      <div class="icon">
        <div class="i">
            ${data.post_image}
        </div>
      </div>
    </div>
  </a>`;

    return post;
  }

  for (const key in events) {
    if (Object.hasOwnProperty.call(events, key)) {
      const el = renderPost(events[key]);
      renderedEvents += el;
    }
  }
  for (const key in archives) {
    if (Object.hasOwnProperty.call(archives, key)) {
      const el = renderPost(archives[key]);
      renderedArchives += el;
    }
  }

  return {
    events: renderedEvents,
    archives: renderedArchives
  };

};
