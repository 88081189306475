import "./utils/index";
import "sharer.js";
import sliderSoft from "./modules/slider-soft";
import sliderInstagram from "./modules/slider-insta";
import sliderFuture from "./modules/slider-future";
import sliderMedia from "./modules/slider-media";
import sliderArchive from "./modules/slider-archive";
import Faq from "./modules/faq";
import search from "./modules/search";
import toggleMenu from "./modules/toggle-menu";
import privacyTags from "./modules/mobile-privacy";
import { onscrollAnimations } from "./modules/animations/animations-onscroll";
import { onloadAnimations } from "./modules/animations/animations-onload";
import { featuresScroll } from "./modules/scroll-features";
import { customPointer } from "./modules/animations/animations-pointer";
// import { eventsScroll } from "./modules/scroll-events";
import { getStartedScroll } from "./modules/scroll-started";
import { ajaxLoader } from "./modules/ajax-loader";
import { shared } from "./modules/shared";
import { footerNav } from "./modules/footer-nav";
// import youtubeVideo from "./modules/create-video";
import animationsPreloader from "./modules/animations/animations-preloader";
import Form from "./modules/form";
import ZoomImage from "./modules/zoom-image";
import Notified from "./modules/notified";
// import articleNavigation from "./modules/article-navigation";



animationsPreloader();
sliderSoft();
sliderInstagram();
sliderFuture();
sliderMedia();
sliderArchive();
Faq();
search();
toggleMenu();
privacyTags();

featuresScroll();
// eventsScroll();
getStartedScroll();

onloadAnimations();
onscrollAnimations();
customPointer();
footerNav();

ajaxLoader();
shared();
Form();
ZoomImage();
Notified();


const homePageLogo = document.querySelector(".home header .logo");
homePageLogo &&
  homePageLogo.addEventListener("click", () => {
    window.location.reload();
  });
