import { gsap, Observer } from "gsap/all";
gsap.registerPlugin(Observer);

function sliderFuture() {
  const slider = document.getElementById("futureSlider");
  if (!slider) return;

  const cont = slider.querySelector(".slider");
  const nav = slider.querySelector(".navigation");
  const description = slider.querySelector(".description");
  const navButtons = nav.querySelectorAll(".arrow");

  let tl = gsap.timeline();
  let isAnimating = false;

  const nextSlide = () => {
    const icons = cont.children;
    let icon_clone = icons[0];
    icons[0].remove();
    cont.append(icon_clone);

    changeInfo();
  };

  const prevSlide = () => {
    const icons = cont.children;
    let icon_clone = icons[icons.length - 1];
    icons[icons.length - 1].remove();
    cont.prepend(icon_clone);

    changeInfo();
  };

  const changeInfo = () => {
    const active = cont.children[4];
    isAnimating = true;

    gsap
      .timeline()
      .to(
        description,
        { duration: 0.5, autoAlpha: 0, y: 10, ease: "none" },
        "toggle"
      )
      .call(
        () => {
          description.innerHTML = active.dataset.d;
          isAnimating = false;
        },
        null,
        "toggle+=1.4"
      )
      .to(
        description,
        { duration: 0.5, delay: 0.5, autoAlpha: 1, y: 0, ease: "none" },
        "toggle+=1"
      );
  };

  navButtons.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      if (isAnimating) return;
      btn.classList.contains("next") && nextSlide();
      btn.classList.contains("prev") && prevSlide();
    });
  });

  // tl.to({}, { duration: 5, onRepeat: nextSlide, repeat: -1 });

  const onHover = (el) => {
    el.addEventListener("mouseenter", (e) => tl.pause());
    el.addEventListener("mouseleave", (e) => tl.restart());
  };
  onHover(cont);
  onHover(nav);

  const sliderObserver = Observer.create({
    target: slider,
    type: "wheel,touch,pointer",
    axis: "x",
    tolerance: 50,
    onPress: () => (slider.style.cursor = "grabbing"),
    onRelease: () => (slider.style.cursor = "grab"),
    onRight: () => !isAnimating && prevSlide(),
    onLeft: () => !isAnimating && nextSlide(),
  });
}

export default sliderFuture;
