import { gsap, Observer } from "gsap/all";
gsap.registerPlugin(Observer);

export class ScrollBar {

  constructor(options) {

    const {
      scroller = this._getScroller(options?.scroller),
      draggable = true,
      isMobile = 1024,
      direction = 'y'
    } = options || {};

    this.initialized = false;
    this.isActive = false;

    this.bodyScroll = !options?.scroller;
    this.scroller = scroller;

    this.wrapper = this._getWrapper();
    this.isDraggable = draggable !== false;
    this.isMobile = isMobile;
    this.direction = direction;
    this.posY = this.scroller.scrollTop;

    this._debounce = this._debounce.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this._setup();
  }

  static create(options) {
    return new ScrollBar(options);
  }

  _setup() {
    const refresh = this._debounce(() => {
      if (window.innerWidth > this.isMobile) {
        this._init();
      } else {
        this.kill();
      }
    }, 200);

    this.resizeObserver = new ResizeObserver(entries => refresh());
    this.resizeObserver.observe(this.wrapper);
  }

  _init() {

    this.scrollerHeight = this.scroller.scrollHeight;
    this.wrapperHeight = (this.bodyScroll) ? window.innerHeight : this.wrapper.clientHeight;

    if (this.scrollerHeight <= this.wrapperHeight) {
      this.kill();
      return;
    }

    if (!this.bar) {
      this.bar = document.createElement('div');
      this.track = document.createElement('div');
      this.bar.classList.add('scroll-bar');
      this.bar.style.position = this.bodyScroll ? 'fixed' : 'absolute';
      this.track.classList.add('scroll-track');
      this.bar.appendChild(this.track);
      this.wrapper.appendChild(this.bar);
    }

    this.trackHeight = this.wrapperHeight / (this.scrollerHeight / this.wrapperHeight);
    this.realScrollLength = this.scrollerHeight - this.wrapperHeight;
    this.realTrackPath = this.bar.clientHeight - this.trackHeight;
    this.track.style.height = `${this.trackHeight}px`;
    this.topOffset = this.bar.offsetTop;
    this.setTrackY = gsap.quickSetter(this.track, "y", "px");

    this.initialized = true;
    this.dragHandle();

    // this.wrapper.addEventListener('mouseover', this.start);
    // this.wrapper.addEventListener('mouseenter', this.start);
    // this.wrapper.addEventListener('mouseleave', this.stop);
    this.start();
  }

  _update = () => {

    if (!this.initialized || !this.isActive) return;

    this.bar && this.bar.classList[this.isVisible ? 'add' : 'remove']('visible');

    if (!this.isDragging) {
      this.trackY = this.realTrackPath * (this.scroller.scrollTop / this.realScrollLength);
    }

    this.setTrackY(this.trackY);
  }

  dragHandle() {

    if (!this.isDraggable) {
      return;
    }

    if (!this.dragObserver) {
      this.dragObserver = Observer.create({
        target: this.track,
        axis: this.direction,
        onPress: (self) => {
          this.bar.classList.add('is-dragging');
          this.wrapper.style.cursor = "grabbing";
          this.scroller.style.pointerEvents = "none";
          this.scroller.style.userSelect = "none";
          this.pointerOnTrack = self.event.offsetY + this.topOffset;
        },
        onRelease: () => {
          this.bar.classList.remove('is-dragging');
          this.wrapper.style.cursor = "auto";
          this.scroller.style.pointerEvents = "auto";
          this.scroller.style.userSelect = "auto";
        },
        onDragStart: () => {
          this.isDragging = true;
        },
        onDragEnd: () => {
          this.isDragging = false;
        },
        onDrag: (self) => {
          this.trackY = Math.max(0, Math.min(self.event.clientY - this.pointerOnTrack, this.realTrackPath));
          this.posY = Math.max(0, Math.min(this.trackY * this.realScrollLength / this.realTrackPath, this.realScrollLength));
          this.scroller.scrollTop = this.posY;
        }
      });
    }
  }

  start() {
    if (this.isActive) return;
    this.isVisible = true;
    this.isActive = true;
    this.dragObserver && this.dragObserver.enable();
    gsap.ticker.add(this._update);
  }

  stop() {
    if (!this.isActive) return;
    this.isVisible = false;
    this.isActive = false;
    this.dragObserver && this.dragObserver.disable();
    gsap.ticker.remove(this._update);
  }

  kill() {

    if (!this.initialized) return;

    this.stop();

    if (this.dragObserver) {
      this.dragObserver.kill();
      this.dragObserver = null;
    }

    if (this.bar) {
      this.bar.remove();
      this.bar = null;
    }
  }

  _getScroller(scroller) {

    switch (typeof scroller) {
      case 'object':
        return scroller;
      case 'string':
        return document.querySelector(scroller);
      default:
        return document.scrollingElement
          || document.documentElement
          || document.body.parentNode
          || document.body;
    }
  }

  _getWrapper() {

    if (this.bodyScroll) {
      return (this.scroller === document.body && document.documentElement)
        ? document.documentElement
        : this.scroller;
    }

    return this.scroller.parentNode;
  }

  _debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
}
