const footerNav = () => {
  const footer = document.querySelector("footer .nav-pages");
  if (!footer) return;

  const links = footer.querySelectorAll("a");

  const onEnter = (x) =>
    links.forEach((i, d) => i.classList.toggle("hovered", x !== d));
  const onLeave = (e) => links.forEach((i) => i.classList.remove("hovered"));

  links.forEach((icon, i) => {
    icon.addEventListener("mouseenter", (e) => onEnter(i));
    icon.addEventListener("mouseleave", (e) => onLeave());
  });
};

export { footerNav };
