import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const addScrollerBottomMargin = (wrapper, items) => {
  if(!items.length) return;
  const lastItem = items[items.length - 1];
  const wrapperHeight = wrapper.clientHeight;
  const lastItemHeight = lastItem.clientHeight;
  lastItem.style.marginBottom = `${wrapperHeight - lastItemHeight}px`;
}

const createScrollingContainer = (scroller, items, callback) => {

  addScrollerBottomMargin(scroller, items);

  items.forEach((el, idx) => {
    ScrollTrigger.create({
      scroller,
      trigger: el,
      // markers: true,
      start: "top 20%",
      end: `${el.offsetHeight}px`,
      onToggle(e) {
        (e.isActive) && callback(idx);
      }
    });
  });
}

export { createScrollingContainer, addScrollerBottomMargin };