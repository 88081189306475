import gsap from "gsap";

gsap.config({ nullTargetWarn: false });

function sliderMedia() {
  const media = document.getElementById("sliderMedia");
  if (!media) return;

  const navs = media.querySelectorAll(".nav");
  const slides = media.querySelectorAll(".slide");

  let tl;
  let firstStart = true;
  let autoPlay = true;
  let delay = 2;
  let index = 0;
  let startPos;
  let isSwiping = false;

  let yDetect = () => {
    return window.innerWidth > 1024 ? -5 : 0;
  }
  let xDetect = () => {
    return window.innerWidth > 1024 ? 0 : -5;
  }

  const changeActiveIndex = (idx) => {

    index = idx ?? index + 1;
    index == slides.length && (index = 0);
    index < 0 && (index = slides.length - 1);

    slides.forEach((slide, idx) => {
      slide.classList.toggle("active", idx == index);
    });

    navs.forEach((nav, idx) => {
      nav.classList.toggle("active", idx == index);
    });

    animate(slides[index]);
  }

  const animate = (target) => {

    tl = gsap.timeline({
      onComplete: () => {
        autoPlay && changeActiveIndex();
      }
    });

    if (firstStart) {
      firstStart = false;
      tl
        .to(target, { duration: 0.75, autoAlpha: 1, filter: "blur(0px)", yPercent: yDetect })
        .to([], { duration: delay });
    } else {

      tl
        .to(slides, { duration: 0.75, autoAlpha: 0, filter: "blur(1px)", yPercent: 0, xPercent: xDetect })
        .to(target, { duration: 0.75, autoAlpha: 1, filter: "blur(0px)", yPercent: yDetect, xPercent: 0 })
        .to([], { duration: delay });
    }
  }

  const play = () => {
    autoPlay = true;
    tl.play();
  }

  const pause = () => {
    autoPlay = false;
    tl.pause();
  }

  const pauseOnHover = (el) => {
    el.addEventListener("mouseenter", pause);
    el.addEventListener("mouseleave", play);
  };

  navs.forEach((n, i) => {
    n.addEventListener("click", (e) => {
      changeActiveIndex(i);
    });
    pauseOnHover(n);
  });

  pauseOnHover(media);
  animate(slides[0]);
  swipeMedia();



  // Swipe
  function swipeMedia() {

    const getPositionX = (e) => e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;

    const onTouchMove = e => {

      if (isSwiping) return;
      isSwiping = true;

      pause();

      let currentPos = getPositionX(e);

      if (currentPos < startPos) {
        changeActiveIndex(index + 1);
      }

      else if (currentPos > startPos) {
        changeActiveIndex(index - 1);
      }
    }

    const onTouchStart = e => {
      startPos = getPositionX(e);
      media.addEventListener('touchmove', onTouchMove);
    }

    const onTouchEnd = e => {
      isSwiping = false;
      media.removeEventListener('touchmove', onTouchMove);
      play();
    }

    media.addEventListener('touchstart', onTouchStart);
    media.addEventListener('touchend', onTouchEnd);
  }




}

export default sliderMedia;
