const faqList = () => {
  const faq = document.querySelector("section.faq");
  if (!faq) return;

  const accordion = faq.querySelector(".questions");

  toggleQuestion(accordion);

  function toggleQuestion(accordion) {
    let buttons = [...accordion.querySelectorAll("button")];
    if (!buttons.length) return;

    const togglePanels = (clicked) => {
      buttons.forEach((button, idx) => {
        let panel = button.nextElementSibling;
        panel.style.maxHeight = idx === clicked ? panel.scrollHeight + "px" : 0;
        button.classList.toggle("active", idx === clicked);
      });
    };

    buttons.forEach((button, idx) => {
      button.addEventListener("click", () => {
        let panel = button.nextElementSibling;

        if (button.classList.contains("active")) {
          button.classList.remove("active");
          panel.style.maxHeight = 0;
        } else {
          button.classList.add("active");
          togglePanels(idx);
        }
      });
    });
  }
};

export default faqList;
