import gsap from "gsap";
import ShuffleText from "shuffle-text";

const onloadAnimations = () => {
  const main = document.querySelector("main");
  const footer = document.querySelector("footer");
  // const header = document.querySelector("header");
  // const aside = document.querySelector("aside");

  // Add data-title attributes to links with gradient bg
  const addTitleAttributes = () => {
    const footerLinks = [...document.querySelectorAll("footer .nav-pages a")];
    if (!footerLinks.length) return;

    footerLinks.forEach((link) => {
      link.dataset.title = link.textContent;
    });
  };

  const shuffleLinksOnHover = () => {
    if (!footer) return;
    let links = [...footer.querySelectorAll(".links a")];

    links.length &&
      links.forEach((link) => {
        link.style.minWidth = `${link.clientWidth * 1.1}px`;
        let text = new ShuffleText(link);
        link.onmouseover = () => {
          text.start();
        };
      });
  };

  // Add bottom margin to main section to show footer
  const addBottomMargin = () => {
    if (!footer) return;

    const resizeObserver = new ResizeObserver((entries) => {
      main.style.marginBottom = `${footer.clientHeight}px`;
    });

    resizeObserver.observe(document.body);
  };

  addTitleAttributes();

  gsap.matchMedia().add(
    "(min-width: 1024px)", () => {
      shuffleLinksOnHover();
      addBottomMargin();
    }
  );
};

export { onloadAnimations };
