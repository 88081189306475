export default function renderCommunities(data) {

  let posts = data.posts.communities;
  let rendered = '';

  const renderPost = (data) => {

    let desc = data.desc ? `<p class="info">${data?.desc}</p>` : '';
    let tagsList = '';


    if (data.tags) {
      tagsList = `<div class="tags_list">`;

      for (const key in data.tags) {
        if (Object.hasOwnProperty.call(data.tags, key)) {
          tagsList += `<div class="tag dark">${data.tags[key].name}</div>`;
        }
      }
      tagsList += `</div>`;
    }

    let post = `<div class="community_icon post" data-id="${data.id}" data-item>
    <a href="${data?.link}">
      <div class="icon">
        <div class="i">${data?.post_image}</div>
      </div>
    </a>
    <div class="data_top">
      <div class="data text_s">${data?.post_date}</div>
    </div>
    <h4 class="title">${data?.title}</h4>
    ${tagsList}
    ${desc}
  </div>`;

    return post;
  }

  for (const key in posts) {
    if (Object.hasOwnProperty.call(posts, key)) {
      const el = renderPost(posts[key]);
      rendered += el;
    }
  }

  return rendered;

};
