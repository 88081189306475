export default function renderModels(data) {

  let posts = data.posts.models;
  let rendered = '';

  const renderPost = (data) => {

    let date = data.git_date ?? data.post_date;
    let gitStarsTitle = 'GitHub Stars';
    let gitStarsCount = data.git_stars > 0
      ? `<div class="git text_s">
            ${gitStarsTitle}
            <span class="stars">${data.git_stars}</span>
        </div>`
      : '';
    let tagsList = '';

    if (data.tags) {
      tagsList = `<div class="tags_list">`;

      for (const key in data.tags) {
        if (Object.hasOwnProperty.call(data.tags, key)) {
          tagsList += `<div class="tag dark">${data.tags[key].name}</div>`;
        }
      }
      tagsList += `</div>`;
    }

    // <div class="data text_s">
    //   ${date}
    // </div>

    let post = `
    <a href="${data.post_link}" class="column model_icon post" data-item target="${data.post_link_target}">
        <div>
          <div class="data_top">

          ${gitStarsCount}
          </div>
          <h4 class="title">${data.title}</h4>
          <div class="data_center text_s">
              <div class="frame">${data.type}</div>
              <div class="f">${data.icon_image}</div>
          </div>
        </div
        <div>
          <div class="icon">
              <div class="i">${data.post_image}</div>
              <p class="info">${data.excerpt}</p>
          </div>
          ${tagsList}
        </div>
    </a>
`;

    return post;
  }

  for (const key in posts) {
    if (Object.hasOwnProperty.call(posts, key)) {
      const el = renderPost(posts[key]);
      rendered += el;
    }
  }

  return rendered;

};
