const shared = () => {
  const cont = document.querySelector(".share_nav");
  if (!cont) return;

  const icons = cont.querySelectorAll(".icon");

  const onEnter = (x) => icons.forEach((i, d) => i.classList.toggle("hovered", x !== d));
  const onLeave = (e) => icons.forEach((i) => i.classList.remove("hovered"));

  icons.forEach((icon, i) => {
    icon.addEventListener("mouseenter", (e) => onEnter(i));
    icon.addEventListener("mouseleave", (e) => onLeave());
  });
};

export { shared };
