import { addScrollerBottomMargin } from "../utils/create-scroller";
import { gsap, ScrollTrigger, ScrollToPlugin, Observer } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Observer);

const getStartedScroll = () => {

  gsap.matchMedia().add('(min-width: 1024px)', () => {
    init();
  });

  function init() {
    const section = document.querySelector('section.page_started');
    if (!section) return;

    const content = section.querySelector('.content');
    const contentHeader = content.querySelectorAll('.content-header');
    const num = section.querySelector('.active_num');
    const scroller = section.querySelector('.gallery');
    const items = [...scroller.querySelectorAll('.icon')];
    const targetScroller = section.querySelector('[data-scroller-target]');
    const targetScrollerItems = [...targetScroller.querySelectorAll('.soft')];
    const footer = document.querySelector('footer');

    let isScrolling = false;
    let activeIdx = 0;
    let maxIdx = items.length;
    let firstScroll = true;

    const scrollDefaults = {
      // ease: "none",
      ease: "power1.inOut",
      duration: 1
    }

    const changeNum = () => {
      num.textContent = (activeIdx < 9) ? `0${activeIdx + 1}` : activeIdx + 1;
      targetScrollerItems.forEach((item, idx) => {
        item.classList.toggle('active', idx === activeIdx);
      })
    }

    const startedObserver = Observer.create({
      target: window,
      type: "wheel,touch,pointer",
      lockAxis: "x",
      capture: true,
      preventDefault: true,
      tolerance: 50,
      onUp: () => !isScrolling && scrollUp(),
      onDown: () => !isScrolling && scrollDown(),
    });

    const scrollContentHeader = gsap.timeline({
      defaults: scrollDefaults,
      paused: true,
      onComplete: () => {
        changeNum();
        isScrolling = false;
      },
      onReverseComplete: () => {
        isScrolling = false;
        firstScroll = true;
        changeNum();
      }
    })
      .to(content, { y: '-35vh' }, "hide")
      .to(contentHeader, { autoAlpha: 0 }, "hide");

    const scrollContent = () => {
      gsap.timeline({
        defaults: scrollDefaults,
        onComplete: () => {
          isScrolling = false;
          changeNum();
        }
      })
        .to(scroller, { scrollTo: { y: items[activeIdx] } }, "scroll")
        .to(targetScroller, { scrollTo: targetScrollerItems[activeIdx] }, "scroll")
    }

    const scrollToFooter = gsap.timeline({
      paused: true,
      onComplete: () => {
        isScrolling = false;
      },
      onReverseComplete: () => {
        isScrolling = false;
      }
    })
      .to(section, { duration: 1, y: `-${footer.clientHeight - 10}` }, "scroll")
      .to(footer, { duration: 1, y: 0 }, "scroll");

    function scrollUp() {

      if (activeIdx === 0) {
        scrollContentHeader.reverse();
      } else {
        isScrolling = true;

        if (activeIdx > 0 && activeIdx < items.length) {
          activeIdx--;
          scrollContent();
        }

        if (activeIdx === items.length) {
          scrollToFooter.reverse();
          activeIdx--;
        }
      }
    }

    function scrollDown() {

      if (activeIdx === maxIdx) return;

      isScrolling = true;

      if (firstScroll) {
        scrollContentHeader.play();
        firstScroll = false;
      } else {
        activeIdx++;
        (activeIdx === maxIdx) ? scrollToFooter.play() : scrollContent();
      }
    }

    addScrollerBottomMargin(targetScroller, targetScrollerItems);
    addScrollerBottomMargin(scroller, items);
  }
}

export { getStartedScroll }