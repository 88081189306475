import Swiper, { FreeMode } from "swiper";

export default function archiveSlider() {
  const archive = document.querySelector(".archive_events_slider");
  if (!archive) return;
  let w = window.innerWidth;
  // if (w <= 1024) return;

  // window.addEventListener("resize", (e) => (w = window.innerWidth));

  function initSlider(s) {
    const swiper = new Swiper(archive, {
      modules: [FreeMode],
      freeMode: true,
      slidesPerView: "auto",
      grabCursor: true,
    });
  }

  initSlider();
}
