import { _debounce } from "../utils/index";

const search = () => {
  const searchCont = document.querySelector(".column-search");

  if (!searchCont) return;
  const grid = document.querySelector(".grid-posts");
  const categories = [...grid.querySelectorAll(".posts-content")];
  const eventsP = document.querySelector(".events-page");

  const search = searchCont.querySelector(".search input");
  // const reset = search.closest(".search").querySelector(".reset");
  const mobSearch = document.querySelectorAll(".mob_search");

  // search.value = '';

  mobSearch.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      searchCont.classList.toggle("active");
    });
  });

  // // search on input
  // search.addEventListener(
  //   "keyup",
  //   _debounce((e) => {
  //     let str = search.value.trim();
  //     reset.classList.toggle("active", str !== "");
  //   }, 300)
  // );

  // // reset search
  // reset.addEventListener("click", (e) => {
  //   search.value = "";
  //   reset.classList.remove("active");
  // });

  function sortEventsLink() {
    const tags = eventsP.querySelectorAll(".page_title-content .tag");

    tags.forEach((tag, i) => {
      tag.addEventListener("click", (e) => {
        let d = tag.dataset.post;
        tags.forEach((tag, idx) => tag.classList.toggle("active", idx == i));
        d ? sort(d) : reset();
      });
    });

    function sort(data) {
      let id = categories.findIndex((ct) => ct.dataset.post == data);
      let str = search.value;
      categories.forEach((tag, i) =>
        tag.classList.toggle("hidden_tag", id !== i)
      );
      str.length && searchOnInput(str.trim());
    }

    function reset() {
      categories.forEach((tag) => tag.classList.remove("hidden_tag"));
    }
  }
  eventsP && sortEventsLink();
};

export default search;
