import { createScrollingContainer } from "../utils/create-scroller";
import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const featuresScroll = () => {
  return;
  const section = document.querySelector('section.features');
  if (!section) return;

  const num = section.querySelector('.num');
  const scroller = section.querySelector('[data-scroller]');
  const items = [...scroller.querySelectorAll('[data-scroller-item]')];
  // const targetScroller = section.querySelector('[data-scroller-target]');
  // const targetScrollerItems = [...targetScroller.querySelectorAll('.icon')];

  const changeContent = (idx) => {
    num.textContent = (idx < 9) ? `0${idx + 1}` : idx + 1;
    // gsap.to(targetScroller, { scrollTo: targetScrollerItems[idx] });
  }

  gsap.matchMedia().add(
    "(min-width: 1024px)", () => {
      createScrollingContainer(scroller, items, changeContent);
    }
  );




}

export { featuresScroll }