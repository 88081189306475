// Detect webp support
import "webp-in-css/polyfill";

import { detect } from "detect-browser";
import { gsap, ScrollTrigger} from "gsap/all";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { ScrollBar } from "../modules/ScrollBarFactory";

gsap.registerPlugin(ScrollTrigger);
import Lenis from '@studio-freight/lenis';

const browser = detect();

// Add browser to html class
document.getElementsByTagName("html")[0].classList.add(browser ? browser.name : "");

let smoothScroll = null;
let scrollBar = null;

const scrollRAF = time => {
  smoothScroll.raf(time * 1000)
}

gsap.matchMedia().add("(min-width: 1025px)", () => {

  // Custom scroll bar
  scrollBar = ScrollBar.create();

  // Smooth scroll
  smoothScroll = new Lenis({
    duration: 1.8,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    smoothWheel: true,
    wheelMultiplier: 1,
    smoothTouch: false,
    normalizeWheel: true,
    touchMultiplier: 2
  });

  gsap.ticker.add(scrollRAF);

  return () => {

    gsap.ticker.remove(scrollRAF);
    smoothScroll.destroy();
    smoothScroll = null;

    scrollBar.kill();
    scrollBar = null;
  };
});

// Disable / enable scroll
const stopScroll = () => {
  smoothScroll && smoothScroll.stop();
  disableBodyScroll(document.body);
};

const startScroll = () => {
  smoothScroll && smoothScroll.start();
  enableBodyScroll(document.body);
};

function _debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function goBack() {
  const backButtons = document.querySelectorAll("a[data-back]");
  if (!backButtons.length) return;

  backButtons.forEach(button => {
    button.addEventListener("click", e => {
      e.preventDefault();
      window.history.back();
    })
  })
}

goBack();

export { _debounce, stopScroll, startScroll };
